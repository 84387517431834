// This file will replace the default ant design style.

@import '~antd/es/style/themes/index';
@import '~antd/es/style/mixins/index';

@import './antd.global.less';
@import '~antd/es/style/core/iconfont';
@import '~antd/es/style/core/motion';

body {
  @import './antd.override.less';
}

@primary-color: #3351ff;@body-background: #fff;@tooltip-bg: rgba(0, 0, 0, 0.9);@tooltip-max-width: 500px;
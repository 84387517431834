@import '~antd/es/style/themes/default.less';

.banner {
  background: @primary-color;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
  user-select: none;
  position: relative;
  margin-bottom: 20px;
  flex-shrink: 0;
}

.bannerLeft {
  padding: 20px 16px 20px 24px;
}

.bannerRight {
  position: absolute;
  top: 0;
  height: 100%;
  transition: background-color 0.2s @ease-out;
  display: flex;
}

.banner:hover .bannerRight {
  background: lighten(@primary-color, 5%);
}

.bannerLogo {
  margin: 5px 0;
}

.bannerContent {
  margin-left: 15px;
}

.bannerTitle {
  font-size: 1rem;
}

.bannerVersion {
  font-size: 0.8rem;
  opacity: 0.7;
}

@primary-color: #3351ff;@body-background: #fff;@tooltip-bg: rgba(0, 0, 0, 0.9);@tooltip-max-width: 500px;